var kv = {
	current: 0,
	max: 0
}
var kvs = [];
var kvTimer;
var kvSlide = {
	len: 0,
	duration: 6000,
	init: function(){
		kvs = [];
		$(".slideshow").each(function(i){
			var _k = object(kv);
			_k.current = 0;
			_k.max = $(this).find("li:not(.start)").length;
			kvs.push(_k);
		});
		kvSlide.len = kvs.length;
		
		kvTimer = setInterval(function(){
			kvSlide.inc();
		}, kvSlide.duration);
	},
	inc: function(){
		for(var i = 0; i < kvs.length; i++){
			let $slide = $(".slideshow").eq(i);
			if($slide.hasClass("playing")){
				kvs[i].current++;
				if(kvs[i].current >= kvs[i].max){
					kvs[i].current = 0;
				}
			}
		}
		kvSlide.change();
	},
	change: function(){
		for(var i = 0; i < kvs.length; i++){
			let $slide = $(".slideshow").eq(i);
			
			$slide.find("li").removeClass("active");
			$slide.find("li").eq(kvs[i].current).addClass("active");
			$slide.find("li .grid__item-img").removeClass("changed");
			$slide.find("li .grid__item-img").removeClass("active");
			$slide.find("li").eq(kvs[i].current).find(".grid__item-img").addClass("active");
			if(kvs[i].current > 0){
				if( $slide.find("li").eq(0).hasClass("start") ){
					$slide.find("li").eq(0).remove();
				}
			}
		}
		clearInterval(kvTimer);

		kvTimer = setInterval(function(){
			kvSlide.inc();
		}, kvSlide.duration);
	}
}