var Slide = {
	current: 0,
	max: 0
}
var slides = [];
var modalSlides = [];
var slideshow = {
	init: function(){
		slides = [];
		modalSlides = [];

		$("#products .block_product").each(function(i){
			var _s = object(Slide);
			_s.current = 0;
			_s.max = $(this).find("ul.img_slide li").length;
			//console.log(_s.current, _s.max, $(this).find("h4").text());
			if(_s.max > 1){
				var navHTML = '';
				$(this).find("ul.img_slide li").each(function(j){
					/*
					$(this).css({
						"left": j*100 + "%"
					});
					*/
					if(j === 0){
						navHTML += '<li><a class="active" href="javascript:void(0);" title="' + j + '">' + j + '</a></li>';
					}else{
						navHTML += '<li><a href="javascript:void(0);" title="' + j + '">' + j + '</a></li>';
					}
				});
				$(this).find(".nav_imgs ul").html(navHTML);
			}else{
				$(this).find("a.btn_prev").remove();
				$(this).find("a.btn_next").remove();
			}
			slides.push(_s);
		});

		$("#modal_products .block_product").each(function(i){
			var _s = object(Slide);
			_s.current = 0;
			_s.max = $(this).find("ul.img_slide li").length;
			var navHTML = '';
			if(_s.max > 1){
				$(this).find("ul.img_slide li").each(function(j){
					/*
					$(this).css({
						"left": j*100 + "%"
					});
					*/
					if(j === 0){
						navHTML += '<li><a class="active" href="javascript:void(0);" title="' + j + '">' + j + '</a></li>';
					}else{
						navHTML += '<li><a href="javascript:void(0);" title="' + j + '">' + j + '</a></li>';
					}
					
				});
				$(this).find(".nav_imgs ul").html(navHTML);
			}else{
				$(this).find("a.btn_prev").remove();
				$(this).find("a.btn_next").remove();
			}
			modalSlides.push(_s);
		});
		//console.log(modalSlides)
		slideshow.nav();
	},
	nav: function(){
		$("#modal_products a.btn_prev").off().on("click", function(){
			var idx = $(this).attr("data-index");
			slideshow.dec(idx, "modal");
		});
		$("#modal_products a.btn_next").off().on("click", function(){
			var idx = $(this).attr("data-index");
			//console.log(idx);
			slideshow.inc(idx, "modal");
		});
		$("#modal_products .nav_imgs li a").off().on("click", function(){
			let idx = $("#modal_products .nav_imgs").index($(this).parent().parent().parent());
			let iidx = $("#modal_products .nav_imgs").eq(idx).find("li a").index(this);
			dotnav(idx, iidx, "modal");
		});
		
		//---------------------------------------------------------------------------------------
		$("#products a.btn_prev").off().on("click", function(){
			var idx = $(this).attr("data-index");
			slideshow.dec(idx, "");
		});
		$("#products a.btn_next").off().on("click", function(){
			var idx = $(this).attr("data-index");
			slideshow.inc(idx, "");
		});

		
		$("#products .nav_imgs li a").off().on("click", function(){
			let idx = $("#products .nav_imgs").index($(this).parent().parent().parent());
			let iidx = $("#products .nav_imgs").eq(idx).find("li a").index(this);
			dotnav(idx, iidx, "");
		});
		function dotnav(idx, iidx, type){
			if(type === "modal"){
				var s 		= modalSlides[idx],
					$next 	= $("#modal_products .block_product").eq(idx).find("a.btn_next"),
					$prev 	= $("#modal_products .block_product").eq(idx).find("a.btn_prev");
				s.current = iidx;
			}else{
				var s 		= slides[idx],
					$next 	= $("#products .block_product").eq(idx).find("a.btn_next"),
					$prev 	= $("#products .block_product").eq(idx).find("a.btn_prev");
				s.current = iidx;
			}
			
			if(s.current >= s.max-1){
				s.current = s.max-1;
				$next.addClass("disable");
				if($prev.hasClass("disable")){
					$prev.removeClass("disable");
				}
			}else if(s.current === 0){
				if(!$prev.hasClass("disable")){
					$prev.addClass("disable");
				}
				if($next.hasClass("disable")){
					$next.removeClass("disable");
				}
			}else{
				if($next.hasClass("disable")){
					$next.removeClass("disable");
				}
				if($prev.hasClass("disable")){
					$prev.removeClass("disable");
				}
			}
			slideshow.change(idx, type);
		}
		var position,positionY,cx,touching;
		var idx = 0,
		limit = 80;
		var thisSlide;
		$("#modal_products .imgs").off().on({
			'touchstart': function(e) {
				thisSlide = modalSlides[idx];
				idx = $("#modal_products .imgs").index(this);
				if(thisSlide.max <= 1){ return false}

				//sceneTime.timerStop();
				cx 			= 0;
				position 	= getPosition(e);
			},
			'touchmove': function(e) {
				e.preventDefault();
				var touchX 	= event.touches[0].clientX;
				cx 	= position - getPosition(event);
			},
			'touchend': function(e) {
				if(cx < -limit){
					if(thisSlide.current > 0){
						slideshow.dec(idx, "modal");
					}
				}else if(cx > limit){
					if(thisSlide.current < thisSlide.max-1){
						slideshow.inc(idx, "modal");
					}
				}
				cx = 0;
			},
			'touchcancel': function(e){
				cx = 0;
			}
		});
		function getPosition(event){
			return event.touches[0].pageX;
		}
	},
	checkSlideNav: function(idx, type){
		if(type === "modal"){
			var s = modalSlides[idx];
			var $next = $("#modal_products .block_product").eq(idx).find("a.btn_next"),
				$prev = $("#modal_products .block_product").eq(idx).find("a.btn_prev");
		}else{
			var s = slides[idx];
			var $next = $("#products .block_product").eq(idx).find("a.btn_next"),
				$prev = $("#products .block_product").eq(idx).find("a.btn_prev");
		}
		if(s.current <= 0){
			if(!$prev.hasClass("disable")){
				$prev.addClass("disable");
			}
			if($next.hasClass("disable")){
				$next.removeClass("disable");
			}
		}else if(s.current >= s.max-1){
			if(!$next.hasClass("disable")){
				$next.addClass("disable");
			}
			if($prev.hasClass("disable")){
				$prev.removeClass("disable");
			}
		}else{
			if($next.hasClass("disable")){
				$next.removeClass("disable");
			}
			if($prev.hasClass("disable")){
				$prev.removeClass("disable");
			}
		}
	},
	inc: function(idx, type){
		if(type === "modal"){
			var s = modalSlides[idx];
		}else{
			var s = slides[idx];
		}
		s.current++;
		slideshow.checkSlideNav(idx, type);
		slideshow.change(idx, type);
	},
	dec: function(idx, type){
		if(type === "modal"){
			var s = modalSlides[idx];
		}else{
			var s = slides[idx];
		}
		s.current--;
		
	//	console.log(s.current)
		slideshow.checkSlideNav(idx, type);
		slideshow.change(idx, type);
	},
	change: function(idx, type){
		if(type === "modal"){
			/*
			$("#modal_products .img_product").eq(idx).addClass("disable");
			setTimeout(function(){
				var s = modalSlides[idx];
				$("#modal_products .img_product").eq(idx).removeClass(function(index, className) {
					return (className.match(/\bactive\S+/g) || []).join(' ');
				});
				$("#modal_products .img_product").eq(idx).addClass("active" + s.current);
				setTimeout(function(){
					$("#modal_products .img_product").eq(idx).removeClass("disable");
				},400);
			},300);
			*/
			var s = modalSlides[idx];
			let $this = $("#modal_products .img_product").eq(idx);
			$this.find(".img_slide li").removeClass("active");
			$this.find(".img_slide li").eq(s.current).addClass("active");
			
			let $thisNav = $this.find(".nav_imgs");
			$thisNav.find("li a").removeClass("active");
			$thisNav.find("li").eq(s.current).find("a").addClass("active");
		}else{
			var s = slides[idx];
			let $this = $("#products .img_product").eq(idx);
			$this.find("li").removeClass("active");
			$this.find("li").eq(s.current).addClass("active");
			
			let $nav = $this.find(".nav_imgs");
			$nav.find("li a").removeClass("active");
			$nav.find("li").eq(s.current).find("a").addClass("active");
			/*
			$("#products .img_product").eq(idx).addClass("disable");
			setTimeout(function(){
				
				$("#products .img_product").eq(idx).removeClass(function(index, className) {
					return (className.match(/\bactive\S+/g) || []).join(' ');
				});
				$("#products .img_product").eq(idx).addClass("active" + s.current);
				setTimeout(function(){
					$("#products .img_product").eq(idx).removeClass("disable");
				},400);
			},300);
			*/
		}
	}
}

var products = {
	current: 0,
	isFirst: true,
	init: function(){
		products.checkURL();
		console.log("product init")
		$("#products a.btn_modal").off("click").on("click", function(){
			var path = $(this).attr("data-url");
			window.history.pushState(null, null, path);
			products.checkURL();
		});
	},
	checkURL: function(){
		let $body 	= $("body"),
			prop 	= location.pathname,
			folders = prop.split("/"),
			arg 	= new Object,
			pair 	= location.search.substring(1).split('&'),
			isModal	= false;
		checkProp();
		function checkProp(){
			if(pair != ""){
				for(var i = 0; i < pair.length; i++){
					var thisPair = pair[i].split("=");
					if(thisPair[0] === "product"){
						products.current = thisPair[1];
						$("#modal_products ul li.block_product").removeClass("active");
						$("#modal_products ul li#modal_product" + products.current).addClass("active");
						products.openModal();
					}else if(thisPair[0] === "ckcachecontrol" && pair.length === 1){
						
					}
				}
			}else{
				//close
				products.closeModal();
			}
		}
	},
	openModal: function(){
		let $body = $("body");
		if(isSmallScreen){
			$("#modal_products_inner").animate({
				scrollTop : "0px"
			},100);
			if(!$body.hasClass("modal")){
				$body.addClass("modal");
				setTimeout(function(){
					$body.addClass("show_modal");
					$("a.btn_close_modal").off().on("click", function(){
						var path = "/";
						window.history.pushState(null, null, path);
						products.checkURL();
					});
				},50);
			}
		}else{
			var posY = $("#product" + products.current).offset().top - 40;
			common.scrollToPosition(posY);
			var path = "/";
			window.history.pushState(null, null, path);
		}
	},
	closeModal: function(){
		let $body = $("body");
		if($body.hasClass("modal")){
			$body.removeClass("show_modal");
			setTimeout(function(){
				$body.removeClass("modal");
			},600);
		}
	}
}