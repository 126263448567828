var isPage = "";
var Animate = {
	top: 0,
	show: 0
}
var animates = [];
//KV parallax用
var parallax = {
	top: 0,
	height: 0,
	diff: 0,
	id: ""
}
var ps = [];

var urlisChecked = false;
var common = {
	isFirst: true,
	beforeW: 0,
	init: ()=>{
		if(isIPad){
			$(".slideshow.sp").remove();
		}
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		
		common.resize();
		common.nav();
		if(isPage === "index"){
			home.init();
			common.checkURL();
			kvSlide.init();
		}else if(isPage === "contact"){
			contact.init();
		}else{
			
		}
		setTimeout(function(){
			$body.removeClass("disable");
			setTimeout(function(){
				common.scrl();
			}, 300);
		},600);
		$(document).off().on("scroll", function(){
			common.scrl();
		});
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
		let $body = $("body");
		$("a#btn_menu").off().on("click", function(){
			if(!$html.classList.contains("nav")){
				$html.classList.add("nav");
			}else{
				$html.classList.remove("nav");
			}
		});
		$(".bg_nav").off().on("click", function(){
			if($html.classList.contains("nav")){
				$html.classList.remove("nav");
			}
		});
		$("a.btn_mail").off().on("click", function(){
			var name = $(this).attr("data-name") + "についてお問い合わせ";
			var mailContent = "itsuki.oval@gmail.com?subject=" + encodeURIComponent(name);
			location.href = "mailto:" + mailContent;
		});
		$("h6.logo a").off().on("click", function(){
			if(isPage === "index"){
				let path = "/";
				window.history.pushState(null, null, path);
				common.checkURL();
				if($html.classList.contains("nav")){
					$html.classList.remove("nav");
				}
			}
		});
		//Global Nav --------------------------------------
		$(".page_nav li a").off().on("click", function(){
			var id = $(this).attr("data-id");
			if(isPage === "index"){
				if(id != ""){
					var path = "/?page=" + id;
				}
				window.history.pushState(null, null, path);
				common.checkURL();
			}else{
				location.href = "/?page=" + id;
			}
			
			if($html.classList.contains("nav")){
				$html.classList.remove("nav");
			}
		});
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
			products.closeModal();
		} else if(window.matchMedia('all and (min-width : 0px)').matches) {
			isSmallScreen = true;
		}
		
		if(winW != common.beforeW){
			//alert(isIPad, isSmallScreen)
			animates = [];
			$(".a").each(function(i){
				let _a 	= object(Animate);
				_a.top 	= Math.floor($(this).offset().top);
				_a.show = Math.floor(_a.top + window.innerHeight/3);
				animates.push(_a);
			});
			ps = [];
			$('[data-img]').each(function(i){
				let _p = object(parallax);
				_p.top 		= $(this).offset().top;
				_p.height 	= Math.floor( $(this).height() );
				_p.diff 	= $(this).attr("data-y");
				_p.id 		= $(this).attr("id");
				ps.push(_p);
			});
		}
		
		common.beforeW = winW;
		//console.log(ps);
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrollToPosition: (posY) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				if(!urlisChecked){
					urlisChecked = true;
				}
			}
		});
	},
	scrl: () =>{
		var topD 	= $(document).scrollTop(),
			btmD 	= topD + $(window).height(),
			len 	= animates.length;

		for(var i = 0; i < len; i++){
			if(i === len - 1){
				if(btmD >= animates[i].show){
					animateBlock(i)
				}
			}else{
				if(btmD >= animates[i].show){
					animateBlock(i)
				}
			}
		}
		function animateBlock(i){
			var $block = $(".a").eq(i);
			if(!$block.hasClass("animate")){
				$block.addClass("animate");
				if($block.hasClass("slideshow")){
					$block.find("li:eq(0)").addClass("active");
					$block.find("li:eq(0)").find(".grid__item-img").addClass("active");
					if(!$block.hasClass("playing")){
						$block.addClass("playing");
						kvSlide.change();
					}
				}
				setTimeout(function(){
					$block.addClass("animated");
				},1000);
			}
		}
		
		let pslen = ps.length;
		for(var j = 0; j < pslen; j++){
			if(i === pslen - 1){
				if(btmD >= ps[j].top){
					parallax(j)
				}
			}else{
				if(btmD >= ps[j].top){
					parallax(j)
				}
			}
		}
		function parallax(j){
			let p = ps[j];
			let start 	= p.top;
			let blockH 	= window.innerHeight;
			let current = btmD - start;
			let per 	= current/blockH;
			per = per > 1 ? 1 : per;
			let posY 	= 100 -  per*100;
			$("#" + p.id).find("figure").css({
				"background-position-y": posY + "%"
			});

		}
		if(urlisChecked){
			urlisChecked = false;
			window.history.pushState(null, null, "/");
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	checkURL: function(){
		var arg 		= new Object;
		var parameter 	= location.search.substring(1);
		var pair 		= parameter.split('&');
		var isModal 	= false;
		let isPage = "";
		let hasProp = false;
		for(var i = 0; pair[i]; i++) {
			var kv = pair[i].split('=');
			if(kv[0] === "page"){
				isPage = kv[1];
				hasProp = true;
			}
		}
		if(isPage === "product"){
			var blockNum = "";
			for(var i = 0; pair[i]; i++) {
				var kv = pair[i].split('=');
				if(kv[0] === "num"){
					blockNum = kv[1];
				}
			}
			if(blockNum != ""){
				var posY = $("#product" + blockNum).offset().top - 40;
			}else{
				var posY = $("#products").offset().top - 40;
			}
			common.scrollToPosition(posY);
		}else{
			if(isPage != ""){
				var id = "#" + isPage;
				var posY = $(id).offset().top;
				common.scrollToPosition(posY);
			}else{
				var posY = 0;
				common.scrollToPosition(posY);
			}
		}
		if(common.isFirst){
			common.isFirst = false;
			yugami.init();
		}
	}
}
var contact = {
	init: function(){
		contact.check();
		contact.send();
	},
	check: function(){
		$(".required").each(function(){
			$(this).find("input").on("change", function(){
				check();
			});
			$(this).find("textarea").on("change", function(){
				check();
			});
		});
		function check(){
			var arry = [0,0,0,0];
			$(".required").each(function(i){
				let $this = $(this);
				var textareaLen = $(this).find("textarea").length;
				if(textareaLen > 0){
					var v = $this.find("textarea").val();
				}else{
					var v = $this.find("input").val();
				}
				if(v != ""){
					arry[i] = 1;
				}else{
					arry[i] = 0;
				}
			});
			var result = arry.some(function(value){
				return value === 0;
			});
			if(!result){
				$(".btn_submit").addClass("active");
			}else{
				if($(".btn_submit").hasClass("active")){
					$(".btnsubmit").removeClass("active");
				}
			}
		}
	},
	send: function(){
		var wpcf7Elm = document.querySelector( '.wpcf7' );
		wpcf7Elm.addEventListener( 'wpcf7submit', function( event ) {
			setTimeout(function(){
				common.scrollToPosition(0);
				$("#contact_content").addClass("complete");
			},600);
		}, false );
	}
}
var yugami = {
	init: function(){
		if(isIPad){ return false;}
		if(!isIE){
			imagesLoaded( document.querySelectorAll('img'), function(){
				document.body.classList.remove('loading');
			});
			$(".grid__item-img").each(function(e){
				var imgs 	= $(this).find("img");
				var $this 	= $(this)[0];
				new hoverEffect({
					parent: $this,
					intensity: $this.dataset.intensity || undefined,
					speedIn: $this.dataset.speedin || undefined,
					speedOut: $this.dataset.speedout || undefined,
					easing: $this.dataset.easing || undefined,
					hover: $this.dataset.hover || undefined,
					image1: imgs[0].getAttribute('src'),
					image2: imgs[1].getAttribute('src'),
					displacementImage: $this.dataset.displacement
				}, e);
			});
		}else{
			$(".grid__item").remove();
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});