var home = {
	init: function(){
		slideshow.init();
		products.init();
		home.nav();
	},
	nav: function(){
		let $body = $("body");
		//ショッピングガイド
		$("a.btn_guide").off().on("click", function(){
			home.loadGuide().done(function(){
				home.openGuide();
			});
		});
		$(".nav_products li a").off().on("click", function(){
			let dataName = $(this).attr("data-name");
			let posY = $('[data-id="' + dataName + '"]').offset().top - 40;
			//console.log(posY)
			common.scrollToPosition(posY)
		});
	},
	loadGuide: function(){
		var defer = $.Deferred();
		var loadURL = "/shoppingguide/";
		$.get(loadURL,{},function(data){
			var out_html 	= $(data),
				ttl 		= out_html.filter("title")[0].innerHTML,
				$content 	= $("#modal_shoppingguide #shoppingguide_content"),
				content 	= $("#shoppingguide_content #shoppingguide_inner", out_html)[0];
			
			//console.log(content);
			$content.html(content);

			setTimeout(function(){
				defer.resolve();
			},600);
		});
		
		return defer.promise();
	},
	openGuide: function(){
		let $body = $("body");
		$body.addClass("modal_guide");
		setTimeout(function(){
			$body.addClass("show_modal_guide");
			$("#btn_close_guide").off().on("click", function(){
				home.closeGuide();
			});
		},50);
	},
	closeGuide: function(){
		let $body = $("body");
		$body.removeClass("show_modal_guide");
		setTimeout(function(){
			$body.removeClass("modal_guide");
		},600);
	}
}